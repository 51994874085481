<template>
  <div class="setting-router">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      table: {
        data: [],
        query: {
          search: '',
        },
      },
    };
  },
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>
<style scoped lang="scss">
.setting-router {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .header {
    background-color: #fff;
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      color: $h4;
      border-bottom: 1px solid $border1;
      padding: 15px 30px;
      font-weight: bold;
    }
    .header-container {
      padding: 15px 30px;
      display: flex;
      align-items: center;
      .el-input {
        width: inherit;
      }
      .search {
        margin-left: 20px;
      }
      .text {
        font-size: 14px;
        color: $h4;
      }
    }
  }
}
</style>
